export const FRONTEND_BASE_URL = process.env.VUE_APP_FRONTEND_PROXY_BASE_URL;
export const BACKEND_BASE_URL = process.env.VUE_APP_BACKEND_BASE_URL;
export const BACKEND_WEBSOCKET_BASE_URL = process.env.VUE_APP_BACKEND_WEBSOCKET_BASE_URL;


export const SQF_URL = process.env.SQF_URL;

export const SQF_CSS_URL = process.env.SQF_CSS_URL;

export const ENTITY_NAME = "Skole";   // dette var først region

export const STANDARD_PAGINATION_LIMIT = 25;

export const WS_NORMAL_CLOSURE_CODE = 1000;